class Menu {
    menu: any;
    constructor() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        this.swiperPosition();
        // this.height();
        $(window).resize(this.destroy.bind(this));
        // $(window).resize(this.height.bind(this));
        $(window).scroll(this.scroll.bind(this));
        $(window).resize(this.swiperPosition.bind(this));
    }
    toggle(event){
        event.preventDefault();
        $('body').toggleClass('is-activeNav');

    }
    destroy(event) {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    }

    scroll() {
       if($(window).scrollTop() >= 1)    {
          $('.c-nav-controls').addClass('c-nav-controls--bg')
       }else {
         $('.c-nav-controls').removeClass('c-nav-controls--bg')
       }
    }

    swiperPosition() {
      let marginRight = ($(window).outerWidth() - $(".js-width").innerWidth()) / 2;
      $('.swiper').css({"margin-right": `-${marginRight}px`})

      if ($(window).width() >= 821) {
        $('.swiper').css({"margin-right": `-${marginRight}px`})

      } else if ($(window).width() <= 820) {
        $('.swiper').css({"margin-right": `0`})
      };
    }


}
let menu = new Menu();
